import React, { lazy, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import useUnmountSubject from 'hooks/useUnmountSubject';
import Layout from 'components/Layout';
import LazyRender from './hoc/LazyRender';
import 'css/styles.css';
import { ajax } from 'rxjs/ajax';
import { map, takeUntil } from 'rxjs/operators';
import { dialogService } from './subjects/DialogService';
import { ajaxPost } from 'common/ajax';

const Home = lazy(() => import('components/Layout/Home'));
const UserRegistrationModule = lazy(() => import('components/UserRegistration/UserRegistration'));
const ClaimsModule = lazy(() => import('components/Claims'));
const EligibityModule = lazy(() => import('components/Eligibility'));
const Authorizations = lazy(() => import('components/Authorizations'));
const ProviderDataRequest = lazy(() => import('components/ProviderDataRequest'));

const App = () => {

    const navigate = useNavigate();
    const unmountSubscriptions$ = useUnmountSubject();

    const [refreshInterval, setRefreshInterval] = useState(null);

    useEffect(() => {
        ajax('api/Config/ConfigVariables')
            .pipe(
                map((response) => response.response),
                takeUntil(unmountSubscriptions$),
            )
            .subscribe((config) => {
                if (config === null || config === undefined) {
                    return;
                }
                setRefreshInterval(config.refreshInterval);
                var keyValue = config.segmentKey;
                if (keyValue === '' || keyValue === null) {
                    return;
                }
                const script = document.createElement('script');
                script.innerText = !function () {
                    var analytics = window.analytics = window.analytics || [];
                    if (!analytics.initialize)
                        if (analytics.invoked)
                            window.console && window.console.error && console.error('Segment snippet included twice.');
                        else {
                            analytics.invoked = !0;
                            analytics.methods = [
                                'trackSubmit',
                                'trackClick',
                                'trackLink',
                                'trackForm',
                                'pageview',
                                'identify',
                                'reset',
                                'group',
                                'track',
                                'ready',
                                'alias',
                                'debug',
                                'page',
                                'once',
                                'off',
                                'on',
                                'addSourceMiddleware',
                                'addIntegrationMiddleware',
                                'setAnonymousId',
                                'addDestinationMiddleware'
                            ];
                            analytics.factory = function (e) {
                                return function () {
                                    if (window.analytics.initialized)
                                        return window.analytics[e].apply(window.analytics, arguments);
                                    var i = Array.prototype.slice.call(arguments);
                                    i.unshift(e);
                                    analytics.push(i);
                                    return analytics;
                                };
                            };
                            for (var i = 0; i < analytics.methods.length; i++) {
                                var key = analytics.methods[i];
                                analytics[key] = analytics.factory(key);
                            }
                            analytics.load = function (key, i) {
                                var t = document.createElement('script');
                                t.type = 'text/javascript';
                                t.async = !0;
                                t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
                                var n = document.getElementsByTagName('script')[0];
                                n.parentNode.insertBefore(t, n);
                                analytics._loadOptions = i;
                            };
                            analytics._writeKey = keyValue;
                            analytics.SNIPPET_VERSION = '4.16.1';
                        }
                    analytics.load(keyValue);
                    analytics.page();
                }
                    ();

                document.head.appendChild(script);

                return () => {
                    document.head.removeChild(script);
                };
            });
    }, []);

    useEffect(() => {
        let interval = null;
        if (refreshInterval) {
            interval = setInterval(() => {
                ajax('api/Config/VersionNumber')
                    .pipe(
                        map((response) => response.response),
                        takeUntil(unmountSubscriptions$),
                    )
                    .subscribe(latestVersion => {
                        if (latestVersion == null) {
                            return;
                        }

                        const currentVersion = localStorage.getItem('version-number');

                        if (currentVersion === null) {
                            localStorage.setItem('version-number', latestVersion);
                        } else if (currentVersion != latestVersion) {
                            const dialog = {};
                            dialog.header = 'New Version Available';
                            dialog.content = () => (
                                <div style={{ fontSize: 16 }}>
                                    <div style={{ marginBottom: 20 }}>
                                        Alignment Health Provider Tools have received updates. Would you like to refresh the page now to get the updated version (recommended)?
                                    </div>
                                    <div>
                                        Warning: if you entered data in form fields then refresh will require data to be re-entered.
                                    </div>
                                </div>
                            );

                            dialog.width = '40%';
                            dialog.buttons = [
                                {
                                    buttonModel: {
                                        content: 'Refresh Page Now',
                                        cssClass: 'e-submit',
                                    },
                                    click: () => {
                                        navigate('/');
                                        window.location.reload();
                                        ajaxPost('api/Config/LogVersionRefresh', { currentVersion, latestVersion, refreshed: true })
                                            .pipe(
                                                map((response) => response.response),
                                                takeUntil(unmountSubscriptions$),
                                            ).subscribe();
                                        localStorage.setItem('version-number', latestVersion);
                                        dialogService.close();
                                    },
                                },
                                {
                                    buttonModel: {
                                        content: 'Remind Later',
                                        cssClass: 'e-cancel',
                                    },
                                    click: () => {
                                        ajaxPost('api/Config/LogVersionRefresh', { currentVersion, latestVersion, refreshed: false })
                                            .pipe(
                                                map((response) => response.response),
                                                takeUntil(unmountSubscriptions$),
                                            ).subscribe();
                                        dialogService.close();
                                    },
                                },
                            ];
                            dialogService.open(dialog);
                        }
                    });
            }, refreshInterval);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [refreshInterval])

    return (
        <Layout>
            <Routes>
                <Route exact path="/" element={LazyRender(Home)} />
                <Route path="/user-registration" element={LazyRender(UserRegistrationModule)} />
                <Route path="/check-claim-status" element={LazyRender(ClaimsModule)} />
                <Route path="/verify-eligibility" element={LazyRender(EligibityModule)} />
                <Route path="/authorizations" element={LazyRender(Authorizations)} />
                <Route path="/provider-data-update-request" element={LazyRender(ProviderDataRequest)} />
            </Routes>
        </Layout>
    );
};

export default App;
